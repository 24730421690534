/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { css } from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up } from 'styled-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { Data } from '../../components/Slices/Slices';
import * as S from './EditorialReviewSection-styled';
import EditorialSlider from './EditorialSlides';
import Spacer from '../../components/Spacer';
import RichText from '../../components/RichText';
import Heading from '../../components/Heading';

const PublishedReviews: React.FC<Data> = ({ data }) => {
  const { ref, inView } = useInView({ threshold: 0.15});
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  const title = data?.primary?.['editor_slide_title']?.richText?.[0]?.text;

  const publisherLogoList = data?.items?.map((item: any) => {
    const image = item?.company_logo?.gatsbyImageData;
    return image;
  });

  const reviewList = data?.items?.map((item: any, index: any) => {
    const review = item?.review?.richText;
    return (
      <S.ReviewContainer key={index}>
        <RichText
          document={review}
          textSize="f9"
          fontFamily="parnaso"
          lineHeight="1"
          maxWidth="898px"
          alignment="left"
        />
      </S.ReviewContainer>
    );
  });

  return (
    <S.Container ref={ref} inView={inView}>
      <Heading
        as="h2"
        fontFamily="canonatia"
        textSize="f13"
        css={css`
          transform: rotate(-10.28deg);
          font-family: canonatia;
          font-size: 4.25rem;
          max-width: fit-content;
          ${up('tablet-portrait')} {
            transform: translatex(-20px) rotate(-10.28deg);
          }
        `}>
        {title}
      </Heading>
      <EditorialSlider navItems={publisherLogoList}>
        {reviewList}
      </EditorialSlider>
    </S.Container>
  );
};

export default PublishedReviews;
