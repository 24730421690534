import React from 'react';
import styled from 'styled-components';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import Button from '../../components/Button';

const Container = styled.section`
  position: relative;
  padding: 1.5rem 1.5rem;
  max-width: 1440px;
  margin: auto;
`;

const VideoContainer = styled.div`
  border-bottom-left-radius: 20% 33%;
  overflow: hidden;
  position: relative;
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: fit-content;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  z-index: 98;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
`;

const URL_BASE = 'https://player.vimeo.com/video';
const videoConfig = 'badge=0&autopause=0&player_id=0&app_id=58479&controls=0&autoplay=1&loop=1&muted=1'

const FullScreenVideo: React.FC<{ data: any }> = ({ data: { primary }}) => {
  const videoLink = new URL(`${URL_BASE}/${primary.video_id}?${videoConfig}`)

  return (
    <Container>
      <VideoContainer>
        <ContentWrapper>
          <RichText
            document={primary?.video_text?.raw}
            alignment="right"
          />
          <Spacer spacing="md1" />
          <Button
            color={primary.video_button_color}
            url={primary?.video_cta_link?.raw?.url}
          >
            {primary?.cta_text}
          </Button>
        </ContentWrapper>
        <VideoWrapper>
        <iframe
          src={videoLink.href}
          frameBorder="0"
          allow="autoplay"
          style={{position: 'absolute', inset: '0', width: '100%', height: '100%'}}
          title="Application 6"
        />
        <script src="https://player.vimeo.com/api/player.js"></script>
        </VideoWrapper> 
      </VideoContainer>
    </Container>
  )
}

export default FullScreenVideo;