import React, { useEffect, useState } from 'react';
import BodyText from '../../../BodyText';
import X from '../../../../assets/x';
import { Button, Container, SubContainer, CloseButton } from './styled';

const MiniPopUp: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => {
    setIsVisible(false);
  }

  return isVisible ? (
    <Container tabIndex={-1} >
      <SubContainer>
        <CloseButton onClick={handleClose}>
          <X fill="lightgray" height="20px" width="20px" />
        </CloseButton>
        <Button onClick={onClick}>
          <BodyText textColor='white'>
            GET 20% OFF
          </BodyText>
        </Button>
      </SubContainer>
    </Container>
  ) : null;
};

export default MiniPopUp;
