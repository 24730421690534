import React, { FC, Fragment } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import Marquee from 'react-fast-marquee';
import Icon from '../../assets/OrceIcon';
import { Data } from '../../components/Slices/Slices';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import Button from '../../components/Button';
import * as S from './AboutSection-styled';
import BodyText from '../../components/BodyText';

const AboutSection: FC<Data> = ({ data }) => {
  const { ref, inView } = useInView({ threshold: 0});
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  const { primary } = data ?? {};
  const {
    about_title,
    about_paragraph,
    about_image,
    about_button_content,
    about_button_link,
    marquee_content
  } = primary ?? {}; 
  const marqueeList = marquee_content.split(',')?.map((content: string) => (
    <Fragment key={content}>
      <BodyText textColor="offWhite">
        {content}
      </BodyText>
      <Spacer spacing="lg1"  direction="vertical" />
    </Fragment>
  ))
  
  return (
    <>
      <Spacer spacing="md2" />
      <S.Container ref={ref} inView={inView}>
        <S.MetaSection>
          <RichText
            document={about_title?.richText}
            fontFamily="helvetica"
            textSize="f3"
          />
          <Spacer spacing="sm4" />
          <RichText
            document={about_paragraph?.richText}
          />
          {about_button_content && about_button_link && (
            <>
              <Spacer spacing="md3" />
              <Button
                type={about_button_link?.link_type}
                url={about_button_link?.url ?? ''}
                color="navy"
              >
                {about_button_content}
              </Button>
            </>
          )}
        </S.MetaSection>
        {!isTabletUp && <Spacer spacing="lg1" />}
        <S.ImageContainer>
          <Icon
            width="5.75rem"
            height="5.75rem"
            fill="#C79C5E"
            css={`
              position: absolute;
              z-index: 1;
              right: 1rem;
              top: -3rem;
            `}
          />
          <GatsbyImage image={about_image?.gatsbyImageData} alt="" loading="lazy" />
          <S.MarqueeContainer>
            <Marquee gradient={false} speed={40} >
              {marqueeList}
            </Marquee>
          </S.MarqueeContainer>
        </S.ImageContainer>
      </S.Container>
      <Spacer spacing="md4" />
    </>
  )
};


export default AboutSection;
